<template>
  <vue-apex-charts
    type="donut"
    height="400"
    :options="apexChatData.donutChartByStatus.chartOptions"
    :series="apexChatData.donutChartByStatus.series"
  />
</template>

<script>
import messages from '@/plugins/showMessageMixin'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartDataSector'

export default {
  components: {
    VueApexCharts,
  },

  mixins: [messages],

  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  setup() {
    return {
      apexChatData,
    }
  },

  created() {
    if (this.data.length > 0) {
      this.setDonutChartData()
    }
  },

  methods: {
    setDonutChartData() {
      const chartData = this.sumTotalValuePerSector()
      const { series, chartOptions: { labels, plotOptions } } = this.apexChatData.donutChartByStatus
      const { total } = plotOptions.pie.donut.labels

      if (chartData.length > 0) {
        total.value = this.sumTotalAmount(chartData)

        series.length = 0
        labels.length = 0

        chartData.forEach(({ amount, sector_name: sectorName }) => {
          if (amount > 0) {
            series.push(amount)
            labels.push(sectorName)
          }
        })

        return
      }

      this.showMessage({ title: 'Nenhuma informação encontrada' })
    },

    sumTotalValuePerSector() {
      const { data } = this
      const companyTemp = {}
      const status = ['ABERTO', 'ACOMPANHAMENTO']

      data.forEach(({ amount, sector_name: sectorName, status_name: statusName }) => {
        if (status.includes(statusName)) {
          companyTemp[sectorName] = (companyTemp[sectorName] || 0) + amount
        }
      })

      const array = Object.entries(companyTemp).map(([sectorName, amount]) => ({ sector_name: sectorName, amount }))

      return array
    },

    sumTotalAmount(chartData) {
      return chartData.reduce((total, { amount }) => total + amount, 0)
    },

    async exportCsv() {
      try {
        const file = await this.getCsv()

        if (file) {
          saveAs(file)
        }
      } catch (error) {
        this.showErrorMessage(error)
      }
    },

    async getCsv() {
      const companyName = this.data[0].fantasy_name
      const chartData = this.sumTotalValuePerSector()

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(companyName)
      worksheet.columns = [{ header: 'Setor', key: 'sector_name' }, { header: 'Total', key: 'amount' }]

      chartData.forEach(({ amount, sector_name: sectorName }) => {
        if (amount > 0) {
          worksheet.addRow([sectorName, amount])
        }
      })

      const data = await workbook.xlsx.writeBuffer()
      const blob = new File([data], companyName, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

      return blob
    },
  },
}
</script>
