<template>
  <vue-apex-charts
    type="donut"
    height="400"
    :options="apexChatData.donutChartByStatus.chartOptions"
    :series="apexChatData.donutChartByStatus.series"
  />
</template>

<script>
import messages from '@/plugins/showMessageMixin'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartDataStatus'

export default {
  components: {
    VueApexCharts,
  },

  mixins: [messages],

  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  setup() {
    return {
      apexChatData,
    }
  },

  created() {
    if (this.data.length > 0) {
      this.setDonutChartData()
    }
  },

  methods: {
    setDonutChartData() {
      const chartData = this.sumTotalValuePerCompany()
      const { series, chartOptions: { labels, plotOptions } } = this.apexChatData.donutChartByStatus
      const { total } = plotOptions.pie.donut.labels
      total.value = this.sumTotalAmount(chartData)

      series.length = 0
      labels.length = 0

      chartData.forEach(({ amount, fantasy_name: fantasyName }) => {
        if (amount > 0) {
          series.push(amount)
          labels.push(fantasyName)
        }
      })
    },

    sumTotalValuePerCompany() {
      const { data } = this
      const companyTemp = {}

      data.forEach(({ amount, fantasy_name: fantasyName }) => {
        companyTemp[fantasyName] = (companyTemp[fantasyName] || 0) + amount
      })

      const array = Object.entries(companyTemp).map(([fantasyName, amount]) => {
        const isMotocaCompany = fantasyName.split(' ').includes('MOTOCA')

        if (isMotocaCompany) {
          const name = fantasyName.substring(7)

          return ({ fantasy_name: name, amount })
        }

        const [name] = fantasyName.split(' ')

        return ({ fantasy_name: name, amount })
      })

      return array
    },

    sumTotalAmount(chartData) {
      return chartData.reduce((previousValue, { amount }) => previousValue + amount, 0)
    },

    async exportCsv() {
      try {
        const file = await this.getCsv()

        if (file) {
          saveAs(file)
        }
      } catch (error) {
        this.showErrorMessage(error)
      }
    },

    async getCsv() {
      const chartData = this.sumTotalValuePerCompany()
      const statusName = this.data[0]?.status_name

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(`Status - ${statusName}`)
      worksheet.columns = [{ header: 'Filial', key: 'fantasy_name' }, { header: 'Total', key: 'amount' }]

      chartData.forEach(({ amount, fantasy_name: fantasyName }) => {
        if (amount > 0) {
          worksheet.addRow([fantasyName, amount])
        }
      })

      const data = await workbook.xlsx.writeBuffer()
      const blob = new File([data], `RIS - ${statusName}`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

      return blob
    },
  },
}
</script>
