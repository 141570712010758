<template>
  <v-card>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title class="pt-0 d-flex justify-space-between">
            <span>
              RI'S - {{ companySelectedForDonutChart || 'POR FILIAL' }}
            </span>

            <div
              class="pt-6 d-flex justify-center align-center"
              style="width: 50%;"
            >
              <v-autocomplete
                v-model="companySelectedForDonutChart"
                :items="companies"
                item-text="fantasy_name"
                item-value="fantasy_name"
                :loading="isLoadingDonutChartBySector"
                label="Selecione a filial"
                class="d-flex justify-center align-center"
                outlined
                dense
              />

              <v-btn
                icon
                color="warning"
                class="mb-6 ml-2"
                outlined
                dense
                @click="exportCsvBySector()"
              >
                <v-icon>
                  {{ icons.download }}
                </v-icon>
              </v-btn>
            </div>
          </v-card-title>

          <v-card-subtitle class="mb-8">
            Separado por departamento, somente abertos e em acompanhamento
          </v-card-subtitle>

          <v-card-text>
            <DonutChartBySector
              ref="donutChartBySector"
              :key="donutChartBySectorKey"
              :companies-names="companiesNames"
              :data="donutChartDataBySector"
            ></DonutChartBySector>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-title class="pt-0 d-flex justify-space-between">
            <span>
              RI'S - {{ selectedStatus || 'POR STATUS' }}
            </span>

            <div
              class="pt-6 d-flex justify-center align-center"
              style="width: 50%;"
            >
              <v-autocomplete
                v-model="selectedStatus"
                :items="status"
                :loading="isLoadingDonutChartByStatus"
                label="Selecione o status"
                class="d-flex justify-center align-center"
                outlined
                dense
              />

              <v-btn
                color="warning"
                class="mb-6 ml-2"
                icon
                dense
                outlined
                @click="exportCsvByStatus()"
              >
                <v-icon>
                  {{ icons.download }}
                </v-icon>
              </v-btn>
            </div>
          </v-card-title>

          <v-card-subtitle class="mb-8">
            Quantidade separada por filial
          </v-card-subtitle>

          <v-card-text>
            <DonutChartByStatus
              ref="donutChartByStatus"
              :key="donutChartByStatusKey"
              :companies-names="companiesNames"
              :data="donutChartDataByStatus"
            ></DonutChartByStatus>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-divider />

    <v-card-title>
      <span>RI'S - POR FILIAL</span>

      <v-spacer />

      <div
        class="pt-6 d-flex justify-center align-center"
        style="width: 50%;"
      >
        <v-dialog
          ref="dialogDateRange"
          v-model="showCalendar"
          :return-value.sync="selectedDateRange"
          width="400px"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateRange"
              :append-icon="icons.mdiCalendar"
              :rules="[rules.required]"
              label="Selecionar Período"
              :loading="isLoadingCompanyData"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            />
          </template>

          <v-date-picker
            v-model="selectedDateRange"
            width="400px"
            locale="pt-BR"
            scrollable
            range
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="showCalendar = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="getDataByCalendar()"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-btn
          icon
          color="warning"
          class="mb-6 ml-2"
          outlined
          dense
          @click="exportCsvByCompany()"
        >
          <v-icon>
            {{ icons.download }}
          </v-icon>
        </v-btn>
      </div>
    </v-card-title>

    <!-- chart -->
    <v-card-text>
      <chartjs-component-bar-chart
        ref="chartByCompany"
        :key="chartKey"
        :height="100"
        :data="chartjsData.chart.data"
        :options="chartjsData.chart.options"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiDownloadBoxOutline } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import DonutChartBySector from './Charts/Apex-js/DonutChartBySector.vue'
import DonutChartByStatus from './Charts/Apex-js/DonutChartByStatus.vue'
import ChartjsComponentBarChart from './Charts/ChartJs/ChartjsComponentBarChart.vue'
import chartjsData from './Charts/ChartJs/chartjsData'

export default {
  components: {
    DonutChartByStatus,
    DonutChartBySector,
    ChartjsComponentBarChart,
  },

  mixins: [formatters, messages],

  data() {
    return {
      companies: [{}],
      companiesNames: [],
      selectedDateRange: [],

      selectedStatus: '',
      selectedCompany: '',
      companySelectedForDonutChart: '',

      status: ['ABERTO', 'ACOMPANHAMENTO', 'FECHADO'],

      chartKey: 0,
      donutChartByStatusKey: 0,
      donutChartBySectorKey: 0,

      showCalendar: false,
      isLoadingCompanyData: false,
      isLoadingDonutChartByStatus: false,
      isLoadingDonutChartBySector: false,

      chartjsData,
      chartDataWithApi: [],
      processedChartData: [],
      donutChartDataByStatus: [],
      donutChartDataBySector: [],

      icons: { download: mdiDownloadBoxOutline },
    }
  },

  computed: {
    computedDateRange() {
      const formattedDates = this.selectedDateRange.map(date => this.formatDate(date))

      return formattedDates
    },
  },

  watch: {
    async selectedStatus() {
      this.isLoadingDonutChartByStatus = true

      try {
        const response = await this.getCompanyData({ status_name: this.selectedStatus })

        this.donutChartDataByStatus = response
        this.donutChartByStatusKey = this.generateRandomKey()
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.isLoadingDonutChartByStatus = false
      }
    },

    async companySelectedForDonutChart() {
      this.isLoadingDonutChartBySector = true

      try {
        const response = await this.getCompanyData({ fantasy_name: this.companySelectedForDonutChart })

        if (!response.length) {
          this.showMessage({ title: 'Nenhuma informação encontrada' })

          return
        }

        this.donutChartDataBySector = response
        this.donutChartBySectorKey = this.generateRandomKey()
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.isLoadingDonutChartBySector = false
      }
    },
  },

  async created() {
    this.companies = localStorageSlim.get('listCompanies', { decrypt: true })

    this.selectedDateRange = this.getFirstAndLastDayOfMonth()
    this.companySelectedForDonutChart = this.companies[2]?.fantasy_name

    const [status] = this.status
    this.selectedStatus = status

    this.chartDataWithApi = chartjsData.chart.data
  },

  mounted() {
    this.getDataByCalendar()
  },

  methods: {
    /**
     * @typedef {Object} ICompany
     * @property {string} fantasy_name
     * @property {string} sector_name
     * @property {string} status_name
     * @property {number} amount
     */

    /**
     * Retrieves company data by sending a POST request to the dashboard_ri endpoint.
     *
     * @param {Omit<ICompany, 'amount'>} body - The request body object containing the necessary parameters.
     * @return {Promise.<ICompany[]>} A Promise that resolves to the data retrieved from the endpoint.
     */
    async getCompanyData(body = {}) {
      const endpoint = 'api/v1/ri/dashboard_ri'
      const response = await axiosIns.post(endpoint, body)

      return response.data.data
    },

    async getDataByCalendar() {
      try {
        this.isLoadingCompanyData = true

        const { selectedDateRange, $refs, sortDates } = this
        $refs.dialogDateRange.save(selectedDateRange)

        const { startDate, finalDate } = sortDates(selectedDateRange)

        const response = await this.getCompanyData({ fantasy_name: this.selectedCompany, created_date_initial: startDate, created_date_final: finalDate })

        if (!response.length) {
          this.showMessage({ title: 'Nenhuma informação encontrada' })

          return
        }

        this.processedChartData = this.organizeData(response)
        this.setChartJsData(this.processedChartData)
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.isLoadingCompanyData = false
      }
    },

    getFirstAndLastDayOfMonth() {
      const currentDate = new Date()
      currentDate.setMonth(currentDate.getMonth() - 1)

      const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
      const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

      const formatDate = date => {
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')

        return `${year}-${month}-${day}`
      }

      const firstDayFormatted = formatDate(firstDayOfMonth)
      const lastDayFormatted = formatDate(lastDayOfMonth)

      return [firstDayFormatted, lastDayFormatted]
    },

    /**
     * Sets the data for Chart.js based on the provided dataFromChart.
     * @param {ICompany[]} dataFromChart
     */
    setChartJsData(dataFromChart) {
      const labels = Object.keys(dataFromChart)
      const { datasets, labels: chartLabels } = this.chartDataWithApi

      chartLabels.length = 0
      chartLabels.push(...labels)

      const processedData = ['ABERTO', 'ACOMPANHAMENTO', 'FECHADO'].map(statusName => {
        const tempData = labels.map(label => dataFromChart[label][statusName]?.total ?? 0)

        return { label: statusName, data: tempData }
      })

      processedData.forEach(({ label, data }, index) => {
        datasets[index].label = label
        datasets[index].data = data
      })

      this.chartKey = this.generateRandomKey()
    },

    /**
     * @param {Array.<string>} dates
     * @returns {Object<string, string>}
     */
    sortDates(dates) {
      dates.sort((firstDate, secondDate) => new Date(firstDate) - new Date(secondDate))
      const [startDate, finalDate] = dates

      return { startDate, finalDate }
    },

    /**
     * Brazilian standard date format
     *
     * @param {string} date
     * @returns {string}
     */
    formatDate(date) {
      if (typeof date === 'string') {
        const [year, month, day] = date.split('-')

        return `${day}/${month}/${year}`
      }

      return date
    },

    organizeData(data) {
      const organizedData = data.reduce((result, item) => {
        const { fantasy_name: fantasyName, status_name: statusName, amount } = item

        // eslint-disable-next-line no-param-reassign
        result[fantasyName] = result[fantasyName] || {}

        // eslint-disable-next-line no-param-reassign
        result[fantasyName][statusName] = result[fantasyName][statusName] || {
          data: [],
          total: 0,
        }

        result[fantasyName][statusName].data.push(item)
        // eslint-disable-next-line no-param-reassign
        result[fantasyName][statusName].total += amount

        return result
      }, {})

      return organizedData
    },

    generateRandomKey() {
      return Math.random()
    },

    exportCsvBySector() {
      const { donutChartBySector } = this.$refs
      const { companySelectedForDonutChart } = this

      if (companySelectedForDonutChart) {
        donutChartBySector.exportCsv()
      }
    },

    exportCsvByStatus() {
      const { donutChartByStatus } = this.$refs
      const { selectedStatus } = this

      if (selectedStatus) {
        donutChartByStatus.exportCsv()
      }
    },

    exportCsvByCompany() {
      const { chartByCompany } = this.$refs
      const { processedChartData } = this
      const isEmpty = Object.keys(processedChartData).length === 0

      if (!isEmpty) {
        chartByCompany.exportCsv(processedChartData)
      }
    },
  },
}
</script>
