// cSpell: disable

import themeConfig from '@themeConfig'

const $themeColors = themeConfig.themes.light

// colors
const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#003c46',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
    series6: '#dd0939',
  },
  area: {
    series3: '#e0cffe',
    series2: '#b992fe',
    series1: '#ab7efd',
  },
}

export default {
  donutChartByStatus: {
    series: [85, 16, 80, 20.2],

    chartOptions: {
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: '"Inter", sans-serif',
      },
      colors: [
        $themeColors.orange,
        $themeColors.success,
        $themeColors.info,
        $themeColors.warning,
        chartColors.donut.series1,
        chartColors.donut.series3,
        $themeColors.transparentBlack,
        chartColors.donut.series5,
        chartColors.donut.series2,
        chartColors.donut.series6,
      ],
      dataLabels: {
        enabled: true,
        formatter(val) {
          if (Number.isNaN(val)) return '0'

          return `${parseInt(val, 10)}%`
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: '"Inter", sans-serif',
              },
              value: {
                fontSize: '1rem',
                fontFamily: '"Inter", sans-serif',
                formatter(val) {
                  if (Number.isNaN(val)) return '0'

                  return `${parseInt(val, 10)}`
                },
              },

              total: {
                show: true,
                fontSize: '1.5rem',
                label: 'Total',
                value: 201,

                formatter() {
                  return this.value
                },
              },
            },
          },
        },
      },
      labels: ['ADMINISTRATIVO', 'OFICINA', 'CNH', 'COMPRAS'],
      responsive: [
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 380,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 320,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '1.5rem',
                    },
                    value: {
                      fontSize: '1rem',
                    },
                    total: {
                      fontSize: '1.5rem',
                    },
                  },
                },
              },
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    },
  },
}
