import themeConfig from '@themeConfig'

const $themeColors = themeConfig.themes.dark

// colors
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  chart: {
    type: 'bar',
    data: {
      datasets: [
        {
          label: 'Aberto',
          data: [
            50,
            60,
            70,
            180,
            50,
            60,
            70,
            180,
            50,
            60,
            70,
            180,
          ],
          fill: false,
          spanGaps: false,
          lineTension: 0.4,
          pointRadius: 3,
          pointHoverRadius: 3,
          pointStyle: 'circle',
          borderDash: [
            0,
            0,
          ],
          barPercentage: 0.8,
          categoryPercentage: 0.6,
          type: 'bar',
          borderColor: $themeColors.info,
          backgroundColor: $themeColors.info,
          borderWidth: 3,
          hidden: false,
          yAxisID: 'Y1',
        },
        {
          label: 'Acompanhamento',
          data: [
            100,
            200,
            300,
            400,
            100,
            200,
            300,
            400,
            100,
            200,
            300,
            400,
          ],
          fill: false,
          spanGaps: false,
          lineTension: 0.4,
          pointRadius: 3,
          pointHoverRadius: 3,
          pointStyle: 'circle',
          borderDash: [
            0,
            0,
          ],
          barPercentage: 0.8,
          categoryPercentage: 0.6,
          type: 'bar',
          borderColor: $themeColors.warning,
          backgroundColor: $themeColors.warning,
          borderWidth: 3,
          hidden: false,
        },
        {
          label: 'Fechado',
          data: [
            100,
            200,
            300,
            400,
            100,
            200,
            300,
            400,
            100,
            200,
            300,
            400,
          ],
          fill: false,
          spanGaps: false,
          lineTension: 0.4,
          pointRadius: 3,
          pointHoverRadius: 3,
          pointStyle: 'circle',
          borderDash: [
            0,
            0,
          ],
          barPercentage: 0.8,
          categoryPercentage: 0.6,
          type: 'bar',
          borderColor: $themeColors.success,
          backgroundColor: $themeColors.success,
          borderWidth: 3,
          hidden: false,
        },
      ],
      labels: [
        'Imperatriz',
        'Açailândia',
        'Grajau',
        'Itinga',
        'Imperatriz',
        'Açailândia',
        'Grajau',
        'Itinga',
        'Imperatriz',
        'Açailândia',
        'Grajau',
        'Itinga',
      ],
    },
    options: {
      // title: {
      //   display: true,
      //   position: 'top',
      //   fontSize: 14,
      //   fontFamily: 'sans-serif',
      //   fontColor: '#fff',
      //   fontStyle: 'bold',
      //   padding: 10,
      //   lineHeight: 1.2,
      //   text: 'Chart title',
      // },

      layout: {
        padding: {},
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
      legend: {
        display: true,
        position: 'top',
        align: 'center',
        fullWidth: true,
        reverse: false,
        labels: {
          fontSize: 14,
          fontFamily: 'sans-serif',
          fontColor: '#fff',
          fontStyle: 'normal',
          padding: 10,
        },
      },
      scales: {
        xAxes: [
          {
            id: 'X1',
            display: true,
            position: 'bottom',
            type: 'category',
            stacked: false,
            offset: true,
            time: {
              unit: false,
              stepSize: 1,
              displayFormats: {
                millisecond: 'h:mm:ss.SSS a',
                second: 'h:mm:ss a',
                minute: 'h:mm a',
                hour: 'hA',
                day: 'MMM D',
                week: 'll',
                month: 'MMM YYYY',
                quarter: '[Q]Q - YYYY',
                year: 'YYYY',
              },
            },
            distribution: 'linear',
            gridLines: {
              display: true,
              color: chartColors.grid_line_color,
              borderDash: [
                0,
                0,
              ],
              lineWidth: 1,
              drawBorder: true,
              drawOnChartArea: true,
              drawTicks: true,
              tickMarkLength: 10,
              zeroLineWidth: 1,
              zeroLineColor: chartColors.grid_line_color,
              zeroLineBorderDash: [
                0,
                0,
              ],
            },
            angleLines: {
              display: true,
              color: chartColors.grid_line_color,
              borderDash: [
                0,
                0,
              ],
              lineWidth: 1,
            },
            pointLabels: {
              display: true,
              fontColor: chartColors.labelColor,
              fontSize: 10,
              fontStyle: 'normal',
            },
            ticks: {
              display: true,
              fontSize: 12,
              fontFamily: 'sans-serif',
              fontColor: '#fff',
              fontStyle: 'normal',
              padding: 0,
              stepSize: null,
              minRotation: 0,
              maxRotation: 50,
              mirror: false,
              reverse: false,
            },
            scaleLabel: {
              display: false,
              labelString: 'Axis label',
              lineHeight: 1.2,
              fontColor: '#fff',
              fontFamily: 'sans-serif',
              fontSize: 12,
              fontStyle: 'normal',
              padding: 4,
            },
          },
        ],
        yAxes: [
          {
            id: 'Y1',
            display: true,
            position: 'left',
            type: 'linear',
            stacked: false,
            offset: true,
            distribution: 'linear',
            gridLines: {
              display: true,
              color: chartColors.grid_line_color,
              borderDash: [
                0,
                0,
              ],
              lineWidth: 1,
              drawBorder: true,
              drawOnChartArea: true,
              drawTicks: true,
              tickMarkLength: 10,
              zeroLineWidth: 1,
              zeroLineColor: chartColors.grid_line_color,
              zeroLineBorderDash: [
                0,
                0,
              ],
            },
            angleLines: {
              display: true,
              color: chartColors.grid_line_color,
              borderDash: [
                0,
                0,
              ],
              lineWidth: 1,
            },
            pointLabels: {
              display: true,
              fontColor: '#fff',
              fontSize: 10,
              fontStyle: 'normal',
            },
            ticks: {
              display: true,
              fontSize: 12,
              fontFamily: 'sans-serif',
              fontColor: '#fff',
              fontStyle: 'normal',
              padding: 0,
              stepSize: null,
              minRotation: 0,
              maxRotation: 50,
              mirror: false,
              reverse: false,
            },
            scaleLabel: {
              display: false,
              labelString: 'Axis label',
              lineHeight: 1.2,
              fontColor: '#fff',
              fontFamily: 'sans-serif',
              fontSize: 12,
              fontStyle: 'normal',
              padding: 4,
            },
          },
        ],
      },
    },
  },

}
